import PropTypes from "prop-types"
import React from "react"
import DesktopNavMenu from "./DesktopNavMenu"
import MobileNavMenu from "./MobileNavMenu"

const Header = ({ siteTitle }) => {
  return (
    <>
 
      <DesktopNavMenu siteTitle ={siteTitle}/>
      <MobileNavMenu siteTitle ={siteTitle}/>
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
