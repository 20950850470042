import React, { useState } from "react"
import styled from "styled-components"
import { breakpoints } from "../utils/breakpoints"

const MobileNavMenu = ({ siteTitle }) => {
  const [menuOpen, toggleMenuOpen] = useState(false)

  return (
    <MenuBar>
      <div className="logo"><a href="/"><img  src="/images/logo.png" alt="Logo" style={{ backgroundColor: "#1e72bc  !important"}} /></a></div>
      <MenuIconContainer>
        <MenuIcon menuOpen={menuOpen} onClick={() => toggleMenuOpen(!menuOpen)}>
          <div />
          <div />
          <div />
        </MenuIcon>
      </MenuIconContainer>
      <MenuLinks menuOpen={menuOpen}>
      <ul className="clearfix">
                           <li className={ (siteTitle==="Actualites" ? 'active' : '')}><a rel="noreferrer" href="/actualites/">Actualités</a></li>
                           <li className={ (siteTitle==="datasets" ? 'active' : '')}><a rel="noreferrer" href="/jeudedonnees/">Jeu de données</a></li>
                           <li className={ (siteTitle==="publications" ? 'active' : '')} ><a rel="noreferrer" href="/publications/">Publications</a></li>
                           <li><a rel="noreferrer" className="contact" target='_blank' href="https://www.geograndest.fr/portail/fr/contact">Contact</a></li>
                           <li><p>Rendre nous visite sur le site  :  <a rel="noreferrer" target='_blank' href="https://grandest.data4citizen.com/">GE CMS </a> </p></li>
                        </ul>
      </MenuLinks>
    </MenuBar>
  )
}

export default MobileNavMenu

const MenuBar = styled.header`
  @media ${breakpoints.lg} {
    display: none;
  }
  height: 3rem;
  position: fixed;
  width: 100%;
  background: #fff;
  border-bottom: "blue";
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MenuIcon = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  outline: thin-dotted;
  z-index: 11;
  img {
    background-color: #1e72bc !important;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ menuOpen }) => (menuOpen ? "red" : "black")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(45deg)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
      transform: ${({ menuOpen }) =>
        menuOpen ? "translateX(-20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`

const MenuLinks = styled.nav`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #1e72bc;
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  transition: transform 300ms;
  transform: ${({ menuOpen }) =>
    menuOpen ? "translateX(0)" : "translateX(100%)"};

  img {
      background-color: #1e72bc;
    }
  ul {
    width: 100%;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;

    li {
      list-style: none;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
`
