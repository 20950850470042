import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { breakpoints } from "../utils/breakpoints"

const DesktopNavMenu = ({ siteTitle }) => {
  const [background, setBackground] = useState(false)
  const navRef = useRef()

  navRef.current = background
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20
      if (navRef.current !== show) {
        setBackground(show)
      }
    }
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    

    <StyledHeader >
    
      <div className="container">
            <div className="row">
               <div className="col-md-8 col-lg-9">
                  <div className="logo"><a href="/"><img  style={{backgroundColor: "#1e72bc"}} src="/images/logo.png" alt="Logo" /></a></div>
                  <div className="main_menu float-right">
                     <div className="menu">
                        <ul className="clearfix">
                          <li className={ (siteTitle==="Actualites" ? 'active' : '')}><a rel="noreferrer" href="/actualites/">Actualités</a></li>
                           <li className={ (siteTitle==="datasets" ? 'active' : '')}><a rel="noreferrer" href="/jeudedonnees/">Jeu de données</a></li>
                           <li className={ (siteTitle==="publications" ? 'active' : '')} ><a rel="noreferrer" href="/publications/">Publications</a></li>
                           <li  ><a className="contact" target='_blank' rel="noreferrer" href="https://www.geograndest.fr/portail/fr/contact">Contact</a></li>

                        </ul>
                     </div>
                  </div>
               </div>
               <div className="col-md-4 col-lg-3">
               <div className="right_bt"><p>Rendre nous visite sur le site :  <a rel="noreferrer" target='_blank' href="https://grandest.data4citizen.com/">GE CMS </a> </p>
               </div>
               </div>
            </div>
         </div>
         
    </StyledHeader>
    
  )
}

export default DesktopNavMenu

const StyledHeader = styled.header`
  display: none;
  @media ${breakpoints.lg} {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    padding: 0.5rem;
    transition: background 300ms;
    background: #fff;
    box-shadow: 0 15px 30px -25px #000;
    img {
      background-color: #1e72bc;
    }
    ul {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      margin: 0;
      list-style: none;

      li {
        list-style: none;
        float: left;
        padding: 0;
        margin: 0;

        a {
          text-decoration: none;
          transition: color 300ms;

          :hover {
            color: #1e72bc;;
          }
          
        }
      }
    }
  }
`
